import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/Home',
    name: 'Home',
    component: () => import('../views/Home.vue')
  }, {
    path: '/',
    redirect: 'Home',
    component: () => import('../views/Home.vue')
  }, {
    path: '/HomeM',
    name: 'HomeM',
    component: () => import('../views/HomeM.vue')
  }, {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  }, {
    path: '/aboutM',
    name: 'AboutM',
    component: () => import('../views/AboutM.vue')
  }, {
    path: '/news',
    name: 'News',
    component: () => import('../views/News.vue')
  }, {
    path: '/product',
    name: 'Product',
    component: () => import('../views/Product.vue')
  }, {
    path: '/ProductM',
    name: 'ProductM',
    component: () => import('../views/ProductM.vue')
  }
]
const scrollBehavior = () => {
  return { x: 0, y: 0 };
}
const router = new VueRouter({
  mode: 'hash',
  routes,
  scrollBehavior
})

export default router
